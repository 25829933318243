<template>
    <div v-if="important" class="parameter-card-item" :class="detailingClass">
        <div class="parameter-card-item__container">
            <div class="parameter-card-item__header">
                <h4 class="parameter-card-item__title">{{ title }}</h4>
                <i class="fal fa-question-circle parameter-card__icon" @click="showPopupInfo"></i>
            </div>
            <div>
                <div class="parameter-card-item__result">{{ max }}</div>
            </div>
        </div>
    </div>
    <div v-else v-show="structure" class="parameter-card-item unimportant" :class="detailingClass">
        <div class="parameter-card-item__container">
            <div class="parameter-card-item__header">
                <h4 class="parameter-card-item__title">{{ title }}</h4>
                <i class="fal fa-question-circle parameter-card__icon" @click="showPopupInfo"></i>
            </div>
            <p class="parameter-card-item__text">Неважный для профиля параметр
            </p>
        </div>
    </div>
    <popup-info v-if="isInfoPopupVisible" @closePopup="showPopupInfo" :name=title>
        <template v-slot:content>
            <p>{{ descriptionContent }}</p>
        </template>
        <template v-slot:footer v-if="descriptionFooter">
            <p>{{ descriptionFooter }}</p>
        </template>
    </popup-info>
</template>

<script>
import popupInfo from "../../Popup/PopupInfo.vue"
import {ref} from 'vue'

export default {
    name: "parameter-card-item",
    components: {
        popupInfo
    },
    props: {
        title: {
            type: String,
        },
        important: {
            type: Boolean,
        },
        max: {
            type: Number || null,
        },
        min: {
            type: Number || null,
        },
        descriptionContent: {
            type: String
        },
        descriptionFooter: {
            type: String
        },
        detailingClass: {
            type: Array
        },
        structure: {
            type: Boolean
        }

    },
    setup() {
        const isInfoPopupVisible = ref(false)

        const showPopupInfo = ()  => {
            isInfoPopupVisible.value = !isInfoPopupVisible.value
        }


        return {
            showPopupInfo,
            isInfoPopupVisible
        }
    },
    mounted() {

    }
}
</script>

<style lang="scss">
.parameter-card-item {
  color: #3D3D3D;
  width: 238px;
  height: 151px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
  margin: 5px;

  @media (max-width: 850px) {
    width: 110px;
    height: 100px;
  }

  &__container {
    padding: 15px 23px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    position: relative;

    @media (max-width: 850px) {
      padding: 6px 10px 12px 10px;
    }

  }

  &__header {
    display: flex;
    align-items: start;
    justify-content: space-between;
    column-gap: 5px;
  }

  &__title {
    font-weight: 400;
    font-size: 19px;
    line-height: 27px;
    text-align: center;

    @media (max-width: 850px) {
      font-size: 9.2437px;
      line-height: 13px;
    }
  }

  &__result {
    font-weight: 400;
    font-size: 36px;
    line-height: 49px;
    padding-top: 16px;

    @media (max-width: 850px) {
      padding-top: 8px;
      font-size: 16.6387px;
      line-height: 23px;
    }
  }

  &__text {
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    text-align: center;

    @media (max-width: 1320px) {
      font-size: 8px;
      line-height: normal;
    }

    @media (max-width: 850px) {
      font-size: 6px;
      line-height: 8px;
    }
  }

}

.parameter-card__icon {
  color: #d9d9d9;
  position: absolute;
  top: 10px;
  right: 7px;

  @media (width: 1000px) {
    display: none;
  }

  @media (max-width: 850px) {
    top: initial;
    right: 5px;
    bottom: 3px;
  }
}

.progress {
  width: 194px;
  padding-top: 10px;

  @media (max-width: 850px) {
    width: 88.28px;
    padding-top: 7px;
  }
}


progress[value]::-webkit-progress-bar {
  background-color: #eee;
  border-radius: 2px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25) inset;
  height: 3px;
}

progress[value]::-webkit-progress-value {
  background-color: #21B579;
}

.parameter-card-detailing {
  height: 202px;

  @media (max-width: 850px) {
    height: 100px;
  }
}

.unimportant {
  background-color: transparent;
  border: dashed 2px #139460;
}


@media (min-width: 990px) and (max-width: 1320px) {
  .parameter-card-item {
    width: 150px;
    height: 100px;
  }

  .parameter-card-item__title {
    text-align: center;
    font-size: 10px;
    line-height: normal;
  }

  .parameter-card-item__result {
    padding-top: 5px;
    line-height: normal;
    font-size: 16px;
  }
}
</style>