<template>
    <div class="analysis">
      <div class="analysis_text">
        <p>
          Количество людей на каждом из уровней (элементарный, начальный, средний, профессиональный и экспертный) развития ЭИ
          по общему ЭИ, двум доменам, четырем ветвям и 10 разделам методики ТЭИ.
        </p>
      </div>
        <ul class="analysis__list">
            <li v-for="(value, sectionName, index) in sections" v-bind:key="index" class="analysis__content">
                <h3 class="analysis__title">{{ value[0] }}</h3>
                <p class="analysis__average">Средний балл по всей выборке: {{ value[2] }}</p>
                <BarChart :chartData="metadata(sectionName)"
                          :options="charOptions"
                          :width="chartWidth"
                          :height="chartHeight"
                />
            </li>
        </ul>
    </div>
  <div>
    <p class="group_comparison_text">
      Для выявления различий в ЭИ-профилях между сотрудниками с высокими и низкими уровнями достижения KPI,
      были отобраны 2 контрастные группы. Основанием для выделения контрастных групп по уровню KPI является показатель стандартного отклонения общего уровня KPI
      по всей выборке (Анастази и др., 1982).
    </p>
    <additional-comparison :cards="cards" />
  </div>
  <section>
    <h3 class="additional_comparison_header">Структура ЭИ-профиля</h3>
    <div class="ei_schema_image">
      <div class="image_wrapper">
        <img :src="imageSrc" alt="Схема ЭИ-профиля" width="1000" height="668">
      </div>
      <div class="image_details">
        <div class="details_block">
          <div class="color_block green"></div>
          <p class="important_header">Ведущие способности в эталонном ЭИ-профиле клиентского менеджера</p>
        </div>
        <div class="details_block">
          <div class="color_block blank"></div>
          <p>Способности, с не выраженным влиянием на эффективность в эталонном ЭИ-профиле</p>
        </div>
        <div class="details_text">
          <p>В данной инфографике для каждой из 17 способностей ЭИ представлены по два значения:</p>
          <p>
            Первое значение - Критически допустимая нижняя граница по баллам по этой способности.
          </p>
          <p>
            Второе значение - Рекомендуемая граница по этой способности.
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {useStore} from "vuex";
import {computed} from "@vue/runtime-core";
import {BarChart} from "vue-chart-3";
import {ref} from "@vue/reactivity";
import AdditionalComparison from "@/views/Report/AdditionalAnalysis/AdditionalComparison/AdditionalComparison.vue";

export default {
    name: "additional-analysis",
    components: {AdditionalComparison, BarChart},
    props: {
      cards: Array,
      imageSrc: String,
    },
    setup() {
        const store = useStore()
        const users = computed(() => store.state.analytics.referenceReport.users)
        const referenceReport = computed(() => store.state.analytics.referenceReport)
        const sections = {
            'total': ['Общий ЭИ', []],
            'domain_1': ['Опытный ЭИ', []],
            'domain_2': ['Стратегический ЭИ', []],
            'branch_1': ['Распознавание эмоций', []],
            'branch_2': ['Использование эмоций для решения задач', []],
            'branch_3': ['Анализ эмоций', []],
            'branch_4': ['Управление эмоциями', []],
            'section_1': ['Распознавание эмоций в лицах', []],
            'section_5': ['Распознавание эмоций в среде', []],
            'section_9a': ['Распознавание эмоций в контексте ситуаций', []],
            'section_2': ['Применение эмоций в действиях и мышлении', []],
            'section_6': ['Эмпатия', []],
            'section_3': ['Анализ смены эмоций', []],
            'section_7': ['Анализ соединения эмоций в чувства', []],
            'section_9b': ['Анализ изменения эмоций в контексте ситуаций', []],
            'section_4': ['Регуляция своих эмоций', []],
            'section_8': ['Влияние на эмоции других', []],
        }
        const rangeValues = {
            elementary: [],
            primary: [],
            base: [],
            high: [],
            professional: []
        }
        const maximumValue = {
            elementary: [],
            primary: [],
            base: [],
            high: [],
            professional: []
        }
        const colors = {
          'elementary': '#FF3131',
          'primary': '#FF65C3',
          'base': '#8C52FF',
          'high': '#004AAD',
          'professional': '#00BF62',
          'basic': '#2CC185',
          'grey': '#B9B9B9',
        }
        const averageValue = (sectionName) => {
            const sumWithInitial = (users.value.map((user) => user[sectionName])).reduce((a, b) => a + b);
            return ((sumWithInitial / (users.value.map((user) => user[sectionName])).length).toFixed(2));
        }
        const sorting = (user, sectionName, minVal, maxVal) => {
            return user[sectionName] >= minVal && user[sectionName] <= maxVal
        }
        const chartData = (sectionName) => {
            return [
                rangeValues.elementary[sectionName] = users.value.filter((user) => sorting(user, sectionName, 0, 69)),
                rangeValues.primary[sectionName] = users.value.filter((user) => sorting(user, sectionName, 70, 89)),
                rangeValues.base[sectionName] = users.value.filter((user) => sorting(user, sectionName, 90, 109)),
                rangeValues.high[sectionName] = users.value.filter((user) => sorting(user, sectionName, 110, 129)),
                rangeValues.professional[sectionName] = users.value.filter((user) => sorting(user, sectionName, 130, 150)),
            ]
        }
        const setColor = (sectionName) => {
            let scoreFrom = referenceReport.value.score[sectionName].score_from
            for (let item in rangeValues) {
                if (rangeValues[item][sectionName].length > 0) {
                    maximumValue[item][sectionName] = rangeValues[item][sectionName].map((item) => {
                        return item[sectionName]
                    });

                    const color = colors[item] ?? colors['basic'];
                    sections[sectionName][1].push(color);
                } else {
                  // обходит ситуацию, когда часть значений отсутствует
                  sections[sectionName][1].push(colors['grey']);
                }
            }
        }
        for (let sectionName in sections) {
            let scoreFrom = referenceReport.value.score[sectionName].score_from
            chartData(sectionName)
            sections[sectionName].push(averageValue(sectionName))
            sections[sectionName].push(scoreFrom)
            setColor(sectionName)
        }
        const setLabel = (context) => {
            let persons = context.dataset.dataPersons[context.dataIndex]
            let women = persons.filter(person => person.sex == 'female')
            let men = persons.filter(person => person.sex == 'male')
            let youngMen = men.filter(man => man.age <= 25)
            let averageMen = men.filter(man => man.age >= 26 && man.age <= 35)
            let matureMen = men.filter(man => man.age >= 36 && man.age <= 55)
            let adultMen = men.filter(man => man.age >= 56)
            let youngWomen = women.filter(woman => woman.age <= 25)
            let averageWomen = women.filter(woman => woman.age >= 26 && woman.age <= 35)
            let matureWomen = women.filter(woman => woman.age >= 36 && woman.age <= 55)
            let adultWomen = women.filter(woman => woman.age >= 56)
            return [
                persons.length + ' человек с уровнем ',
                intervals[context.dataIndex] + ' баллов. ',
                'Подходит условиям KPI. ',
                men.length + ' мужчины ',
                'От 18 до 25: ' + youngMen.length,
                'От 26 до 35: ' + averageMen.length,
                'От 36 до 55: ' + matureMen.length,
                'От 56  и старше: ' + adultMen.length,
                women.length + ' женщин',
                'От 18 до 25: ' + youngWomen.length,
                'От 26 до 35: ' + averageWomen.length,
                'От 36 до 55: ' + matureWomen.length,
                'От 56  и старше: ' + adultWomen.length,
            ];
        }
        const sectionValue = (sectionName) => {
            return [
                rangeValues.elementary[sectionName].length,
                rangeValues.primary[sectionName].length,
                rangeValues.base[sectionName].length,
                rangeValues.high[sectionName].length,
                rangeValues.professional[sectionName].length
            ]
        }
        const intervals = ['0-69', '70-89', '90-95', '96-99', '100-109', '110-129', '130-150']
        const levelDictionary = ['Элементарный', 'Начальный', 'Средний', 'Профессиональный', 'Экспертный']
        const charOptions = ref({
                plugins: {
                    legend: {
                        display: false
                    },
                    subtitle: {
                        display: false,
                        position: 'bottom'
                    },
                    tooltip: {
                        usePointStyle: true,
                        callbacks: {
                            label: function (context) {
                                return setLabel(context)
                            },
                        }
                    }
                },
                scales: {
                    x: {
                        title: {
                          display: true,
                          text: 'уровень ЭИ',
                          font: {
                            size: 14
                          }
                        },
                        ticks: {
                            display: true,
                            padding: 10,
                        },
                        grid: {
                            display: false
                        },
                    },
                    y: {
                        title: {
                          display: true,
                          text: 'количество человек',
                          font: {
                            size: 14
                          }
                        },
                        ticks: {
                            display: true,
                            stepSize: 1
                        },
                        grid: {
                            display: false
                        },
                    },
                },
            }),
            chartWidth = ref(700),
            chartHeight = ref(400)

        const metadata = (sectionName) => {
            return {
                labels: levelDictionary,
                datasets: [
                    {
                        data: sectionValue(sectionName),
                        backgroundColor: sections[sectionName][1],
                        dataPersons: chartData(sectionName),
                        borderRadius: 10,
                        borderSkipped: false,
                    }
                ]
            }
        }
        return {metadata, charOptions, sections, chartWidth, chartHeight, referenceReport};
    }
}
</script>
<style lang="scss" scoped>
.analysis {
  &__list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 40px;

    @media(max-width: 900px) {
      grid-template-columns: 1fr;
    }
  }

  &__content {
    border-bottom: solid 1px #c9c9c9;
    padding-bottom: 10px;
  }

  &__description {
    padding-bottom: 20px;
  }
}

.analysis_text {
  margin-bottom: 20px;
  text-align: justify;
}

.group_comparison_text {
  padding: 40px 0;
  text-align: justify;
}

.ei_schema_image {
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.image_wrapper {
  margin: auto;
}

@media (max-width: 1400px) {
  .image_wrapper img {
    width: 800px;
    height: auto;
  }
}

@media (max-width: 1000px) {
  .image_wrapper img {
    width: 650px;
    height: auto;
  }
}

.image_details {
  padding: 25px 0;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

.details_block {
  display: flex;
  align-items: center;
  column-gap: 10px;
}

.color_block {
  width: 60px;
  height: 30px;
  border: 1px solid #c9e69b;
}

.color_block.green {
  background-color: #1b945f;
}

.important_header {
  font-weight: bold;
}

.analysis__content div {
  height: 310px;
}

.analysis__average {
  padding: 30px 0;
}

.details_text {
  font-size: 14px;
  text-align: justify;
}

</style>
