<template>
    <p>Тестирование прошли {{ sampleData.total }} {{ getWordFormBasedOnTheCount(sampleData.total, 'человек', 'человека', 'человек') }}.</p>
    <div class="samples-box">
        <ul><b>Мужчины:</b><br>{{ sampleData.malePercent }}% ({{ sampleData.maleCount }} {{ getWordFormBasedOnTheCount(sampleData.maleCount, 'человек', 'человека', 'человек') }})
            <li class="box-item">От 18 до 25<span>{{ sampleData.maleYoung }} {{ getWordFormBasedOnTheCount(sampleData.maleYoung, 'человек', 'человека', 'человек') }}</span></li>
            <li class="box-item">От 26 до 35<span>{{ sampleData.maleMiddle }} {{ getWordFormBasedOnTheCount(sampleData.maleMiddle, 'человек', 'человека', 'человек') }}</span></li>
            <li class="box-item">От 35 до 55<span>{{ sampleData.maleAdult }} {{ getWordFormBasedOnTheCount(sampleData.maleAdult, 'человек', 'человека', 'человек') }}</span></li>
            <li class="box-item">От 56 и старше<span>{{ sampleData.maleAged }} {{ getWordFormBasedOnTheCount(sampleData.maleAged, 'человек', 'человека', 'человек') }}</span></li>
        </ul>
        <ul><b>Женщины:</b><br>{{ sampleData.femalePercent }}% ({{ sampleData.femaleCount }} {{ getWordFormBasedOnTheCount(sampleData.femaleCount, 'человек', 'человека', 'человек') }})
            <li class="box-item">От 18 до 25<span>{{ sampleData.femaleYoung }} {{ getWordFormBasedOnTheCount(sampleData.femaleYoung, 'человек', 'человека', 'человек') }}</span></li>
            <li class="box-item">От 26 до 35<span>{{ sampleData.femaleMiddle }} {{ getWordFormBasedOnTheCount(sampleData.femaleMiddle, 'человек', 'человека', 'человек') }}</span></li>
            <li class="box-item">От 35 до 55<span>{{ sampleData.femaleAdult }} {{ getWordFormBasedOnTheCount(sampleData.femaleAdult, 'человек', 'человека', 'человек') }}</span></li>
            <li class="box-item">От 56 и старше<span>{{ sampleData.femaleAged }} {{ getWordFormBasedOnTheCount(sampleData.femaleAged, 'человек', 'человека', 'человек') }}</span></li>
        </ul>
    </div>
  <div class="sample_details">
    <p>По выборке самый высокий показатель по уровню общего эмоционального интеллекта — {{ sampleData.maxGroupScore }} {{ getWordFormBasedOnTheCount(sampleData.maxGroupScore, 'балл', 'балла', 'баллов') }}.</p>
    <p>Самый низкий показатель по уровню общего эмоционального интеллекта — {{ sampleData.minGroupScore }} {{ getWordFormBasedOnTheCount(sampleData.minGroupScore, 'балл', 'балла', 'баллов') }}.</p>
    <p>Самый высокий агрегированный показатель уровня достижения KPI по данной выборке — {{ maxGroupKPI }}.</p>
    <p>Самый низкий агрегированный показатель — {{minGroupKPI}}.</p>
  </div>
    <span class="sample-description-title" style="display: none">Описание выборки:</span>
    <div class="sample-description" style="display: none">
        <p>это статистический анализ эмоционально интеллектуальных профилей сотрудников определенной
            должности в тандеме с их уровнями достижения KPI. Такой анализ использует объективные данные о
            способностях идентификации, использования, понимания и управления эмоциями для выявления
            наиболее важных параметров эмоционального интеллекта, влияющих на эффективность сотрудника на
            рабочем месте.
        </p>
        <p>В этом отчете Вы найдете эталонный ЭИ-профиль сотрудника с выделением ключевых параметров
            эмоционального интеллекта, обеспечивающих эффективность и достижение KPI в рамках
            профессиональной деятельности. ЭИ-профиль используется для найма новых сотрудников, обладающих
            необходимыми компетенциями ЭИ, ротации имеющихся, создания кадрового резерва.
        </p>
    </div>
</template>

<script>
import {ref} from 'vue'
import {useStore} from "vuex";
import {useRoute} from "vue-router";
import {computed} from "@vue/runtime-core";
import {getWordFormBasedOnTheCount} from "@/views/Report/utils";

export default {
    name: "sample-table",
  methods: {getWordFormBasedOnTheCount},
    setup() {
        const store = useStore();
        let sampleData = ref({
            total: null,
            malePercent: null,
            maleCount: null,
            maleYoung: null,
            maleMiddle: null,
            maleAdult: null,
            maleAged: null,
            femalePercent: null,
            femaleCount: null,
            femaleYoung: null,
            femaleMiddle: null,
            femaleAdult: null,
            femaleAged: null
        })

        const sexAgeFemale = computed(() => store.state.analytics.referenceReport.sex_and_age_segregation.female),
            sexAgeMale = computed(() => store.state.analytics.referenceReport.sex_and_age_segregation.male),
            groupResults = computed(() => store.state.analytics.referenceReport.sex_and_age_segregation.analytics);
        if (sexAgeMale.value) {
            sampleData = {
                total: sexAgeFemale.value?.count + sexAgeMale.value?.count,
                malePercent: sexAgeMale.value?.percent.toFixed(1),
                maleCount: sexAgeMale.value?.count,
                maleYoung: sexAgeMale.value?.maleYoung,
                maleMiddle: sexAgeMale.value?.maleMiddle,
                maleAdult: sexAgeMale.value?.maleAdult,
                maleAged: sexAgeMale.value?.maleAged,
                maleMinScore: sexAgeMale.value?.minScore,
                maleMaxScore: sexAgeMale.value?.maxScore,
                femalePercent: sexAgeFemale.value?.percent.toFixed(1),
                femaleCount: sexAgeFemale.value?.count,
                femaleYoung: sexAgeFemale.value?.femaleYoung,
                femaleMiddle: sexAgeFemale.value?.femaleMiddle,
                femaleAdult: sexAgeFemale.value?.femaleAdult,
                femaleAged: sexAgeFemale.value?.femaleAged,
                femaleMinScore: sexAgeFemale.value?.minScore,
                femaleMaxScore: sexAgeFemale.value?.maxScore,
                maxGroupScore: groupResults.value?.max_score,
                minGroupScore: groupResults.value?.min_score,
            }
        }

      const groupScores = computed(() => store.state.analytics.referenceReport.users);
      const minGroupKPI = ref(0);
      const maxGroupKPI = ref(0);

      if (groupScores.value) {
        groupScores.value.forEach((user) => {
          if (!minGroupKPI.value || user.kpi < minGroupKPI.value) {
            minGroupKPI.value = user.kpi;
          }

          if (user.kpi > maxGroupKPI.value) {
            maxGroupKPI.value = user.kpi;
          }
        });
      }

      return {
          sampleData,
          maxGroupKPI,
          minGroupKPI,
      }
    },

    mounted() {
        const store = useStore(),
            route = useRoute();
        store.dispatch("analytics/getReferenceReport", route.params.id)
    }
}
</script>

<style lang="scss" scoped>
.sample-description-title {
  font-weight: 400;
  font-size: 24px;
  line-height: 33px;
}

.samples-box {
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(2, 1fr);
  padding-top: 30px;
  padding-bottom: 40px;

  @media(max-width: 900px) {
    grid-template-columns: 227px;
  }
}

.sample-description {
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(2, 1fr);
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  padding-bottom: 15px;
  border-bottom: solid 1px #c9c9c9;

  @media(max-width: 900px) {
    grid-template-columns: 227px;
    font-size: 12px;
    line-height: 17px;
  }
}

.box-item {
  display: flex;
  justify-content: space-between;
  border-bottom: solid 1px #c9c9c9;
  padding-top: 5px;
  padding-bottom: 5px;
}

.sample_details {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  margin-bottom: 25px;
}
</style>