<template>
    <li v-for="item in items" :key="item.index" :class="item.structureClass">
        <detailed-report-card-item :title="item.title" :range="item.range" :description="item.description"/>
    </li>
</template>

<script>

import DetailedReportCardItem from "./DetailedReportCardItem/DetailedReportCardItem.vue"
export default {
    name: "detailed-report-cards",
    components: {DetailedReportCardItem},
    props: {
        items: {
            type: Array,
        }
    }
}
</script>

<style lang="scss" scoped>
.box-1 {
  grid-area: A;
  padding-bottom: 20px;
  border-bottom: solid 1px #c9c9c9
}

.box-2 {
  grid-area: B;
}

.box-3 {
  grid-area: C;

  @media (max-width: 900px) {
    display: none;
  }
}

.box-4 {
  grid-area: D;
  padding-bottom: 20px;
  border-bottom: solid 1px #c9c9c9;
  border-top: solid 1px #c9c9c9;
}

.box-5 {
  grid-area: E;
  border-top: solid 1px #c9c9c9;
}

.box-6 {
  grid-area: F;
  border-top: solid 1px #c9c9c9;
}

.box-7 {
  grid-area: G;
  border-top: solid 1px #c9c9c9;
}

.box-8 {
  grid-area: H;
}
</style>