<template>
    <div v-if="isPrinting" class="overlay"></div>
    <div v-if="isPrinting" class="modal_block">
      <div class="modal_text_wrapper">
        <p>Файл подготавливается, пожалуйста, подождите...</p>
      </div>
    </div>

    <div class="view-container" id="print_element" v-if="referenceReport">
        <div class="bg-color-green">
            <section class="header_section">
              <div class="references-report-header">
                <div>
                    <span>Результаты по эталонному ЭИ-профилю</span>
                    <h3 data-html2canvas-ignore>Специалист по работе с клиентами в организации "<span>{{ companyName }}</span>"</h3>
                </div>
                <button data-html2canvas-ignore @click="print" class="btn  btn-bg-white no-mobile">Распечатать</button>
              </div>
              <div class="additional_description">
                Проведение описанного статистического анализа позволило выявить эталонный ЭИ-профиль специалиста по работе с клиентами в организации "<span>{{ companyName }}</span>"
                со статистически значимыми различиями и общими тенденциями в связях эмоционального интеллекта с показателями эффективности деятельности.
              </div>
            </section>
            <section class="parameters">
                <div class="parameters-title">Лучших результатов добиваются сотрудники<br>
                    с данными параметрами ЭИ:
                </div>
                <custom-checkbox data-html2canvas-ignore name="format3" label="Показать неважные параметры" v-model="structure" class="checkbox"
                                 :checked="structure"/>
                <ul v-if="structure" class="parameters-cards">
                    <parameters-cards v-if="!isPreloader" :items="cards.values()" :structure="structure"/>
                </ul>
                <ul v-else-if="!structure" class="parameters-cards-no-structure">
                    <parameters-cards v-if="!isPreloader" :items="cards.values()"/>
                </ul>
            </section>
        </div>
        <div class="bg-color-white">
            <section class="detailed-report" style="display: none;">
                <collapse-button :title="`Подробный отчет:`" @click="isOpenDetailed = !isOpenDetailed" style="display: none;"/>
                <Collapse :when="isOpenDetailed" class="collapse">
                    <ul class="detailed-report-cards">
                        <DetailedReportCards :items="detailedBasicItems"/>
                    </ul>
                    <h2>Детализация по ветвям:</h2>
                    <ul class="detailed-report-branches-cards">
                        <DetailedReportCards :items="detailedBranchesItems"/>
                    </ul>
                    <h2>Детализация по разделам:</h2>
                    <ul class="detailed-report-subbranch-cards">
                        <DetailedReportCards :items="detailedSubbranchItems"/>
                    </ul>
                    <button data-html2canvas-ignore @click="print" class="btn ">Распечатать</button>
                </Collapse>
            </section>
            <section class="table" id="table" style="display: none;">
                <users-table ref="usersTable"/>
            </section>
            <section class="conclusion no-scroll" style="display: none;">
                <h3 class="conclusion-title">Выводы:</h3>
                <ul class="conclusion-cards">
                    <li>
                        <img src="@/assets/images/conclusion/conclusion1.svg" alt="">
                        <p class="conclusion-item-constent">ЭИ-профиль используется для найма новых сотрудников,
                            обладающих
                            необходимыми компетенциями ЭИ, ротации имеющихся, создания кадрового резерва, а также для
                            планирования траектории развития персонала, основываясь на объективной информации и точных
                            целях.</p>
                    </li>
                    <li>
                        <img src="@/assets/images/conclusion/conclusion2.svg" alt="">
                        <p class="conclusion-item-constent">В этом отчете Вы найдете эталонный ЭИ-профиль сотрудника с
                            выделением ключевых параметров эмоционального интеллекта, обеспечивающих эффективность и
                            достижение KPI в рамках профессиональной деятельности. ЭИ-профиль используется для найма
                            новых
                            сотрудников, обладающих.</p>
                    </li>
                    <li>
                        <img src="@/assets/images/conclusion/conclusion3.svg" alt="">
                        <p class="conclusion-item-constent">Обеспечивающих эффективность и достижение KPI в рамках
                            профессиональной деятельности. ЭИ-профиль используется для найма новых сотрудников,
                            обладающих
                            необходимыми компетенциями ЭИ, ротации имеющихся, создания кадрового резерва, а также для
                            плана.
                        </p>
                    </li>
                </ul>
            </section>
          <section class="card_section">
            <ul v-if="cards.values().some((el) => el.important)" class="details_list">
              <li class="general_ei">
                <h4 :class="{'important': cards.get('total').important}" class="details_header">Общий ЭИ</h4>
                <ul class="details" v-if="cards.get('total').important">
                  <li class="list_details">
                    <div class="recommended_score">
                      рекомендуемая граница — {{ cards.get('total').max }}
                      {{ getWordFormBasedOnTheCount(cards.get('total').max, 'балл', 'балла', 'баллов') }} и выше
                    </div>
                  </li>
                  <li class="list_details">
                    <div>
                      критически допустимая нижняя граница — не менее {{ cards.get('total').min }}
                      {{ getScoreTranslateForm(cards.get('total').min) }}
                    </div>
                  </li>
                </ul>
              </li>

              <template v-for="(element,key) in listStructure" :key="element">
                <li class="list_element">
                  <div class="list_branch">
                    <h4 :class="{'important': cards.get(key).important}" class="details_header">{{ element.title }}</h4>
                    <ul class="details" v-if="cards.get(key).important">
                      <li class="list_details">
                        <div class="recommended_score">
                          рекомендуемая граница — {{ cards.get(key).max }}
                          {{ getWordFormBasedOnTheCount(cards.get(key).max, 'балл', 'балла', 'баллов') }} и выше
                        </div>
                      </li>
                      <li class="list_details">
                        <div>
                          критически допустимая нижняя граница — не менее {{ cards.get(key).min }}
                          {{ getScoreTranslateForm(cards.get(key).min) }}
                        </div>
                      </li>
                    </ul>

                    <ul class="details_list">
                      <template v-for="(element, key) in element.branches" :key="element">
                        <li v-if="cards.values().find((el) => el.branch === element.title)" class="list_element">
                          <div class="list_branch">
                            <h4 :class="{'important': cards.get(key).important}" class="details_header">{{
                                element.title
                              }}</h4>
                            <ul class="details" v-if="cards.get(key).important">
                              <li class="list_details">
                                <div class="recommended_score">
                                  рекомендуемая граница — {{ cards.get(key).max }}
                                  {{ getWordFormBasedOnTheCount(cards.get(key).max, 'балл', 'балла', 'баллов') }} и выше
                                </div>
                              </li>
                              <li class="list_details">
                                <div>
                                  критически допустимая нижняя граница — не менее {{ cards.get(key).min }}
                                  {{ getScoreTranslateForm(cards.get(key).min) }}
                                </div>
                              </li>
                            </ul>
                          </div>

                          <ul class="details_list">
                            <template v-for="card in cards.values()" :key="card">
                              <li class="list_element" v-if="card.branch === element.title">
                                <ul class="list_branch">
                                  <li>
                                    <h4 :class="{'important': card.important}" class="details_header">{{
                                        card.title
                                      }}</h4>
                                  </li>
                                  <template v-if="card.important">
                                    <li class="list_details">
                                      <div class="recommended_score">
                                        рекомендуемая граница — {{ card.max }}
                                        {{ getWordFormBasedOnTheCount(card.max, 'балл', 'балла', 'баллов') }} и выше
                                      </div>
                                    </li>
                                    <li class="list_details">
                                      <div>
                                        критически допустимая нижняя граница — не менее {{ card.min }}
                                        {{ getScoreTranslateForm(card.min) }}
                                      </div>
                                    </li>
                                  </template>
                                </ul>
                              </li>
                            </template>
                          </ul>
                        </li>
                      </template>
                    </ul>
                  </div>
                </li>
              </template>
            </ul>
            <div v-if="Array.from(cards.values()).find((el) => el.important)" class="additional_resume">
              Специалисты с показателями по данным разделам <span class="recommended_score">не ниже {{ treeValuesSorted.length > 1 ? treeValuesSorted[treeValuesSorted.length - 1] + ' —' : ''}} {{ treeValuesSorted[0] }}  {{ getScoreTranslateForm(treeValuesSorted[0]) }}</span> имеют больше шансов для достижения самого высокого уровня KPI.
            </div>
          </section>

            <router-link data-html2canvas-ignore class="btn btn-sm btn-show-staffs" to="/analytics/reference">
              <span>Посмотреть подходящих сотрудников</span>
            </router-link>
            <section class="theory no-scroll">
                <collapse-button :title="`Выгоды для компании:`" @click="isOpenTheory = !isOpenTheory"/>
                <Collapse :when="isOpenTheory" class="collapse">
                    <ul class="theory-items" style="display: none;">
                        <li>
                            <p class="theory-item-contant">
                                В этом отчете Вы найдете эталонный ЭИ-профиль сотрудника с выделением ключевых
                                параметров
                                эмоционального интеллекта, обеспечивающих эффективность и достижение KPI в рамках
                                профессиональной деятельности.
                                ЭИ-профиль используется для найма новых сотрудников, обладающих необходимыми
                                компетенциями
                                ЭИ, ротации имеющихся, создания кадрового резерва, а также для планирования траектории
                                развития персонала, основываясь на объективной информации и точных целях.
                            </p>
                        </li>
                        <li>
                            <h3>Эталонный профиль:</h3>
                            <p class="theory-item-contant">
                                это статистический анализ эмоционально интеллектуальных профилей сотрудников
                                определенной
                                должности в тандеме с их уровнями достижения KPI. Такой анализ использует объективные
                                данные о способностях идентификации, использования, понимания и управления эмоциями для
                                выявления наиболее важных параметров эмоционального интеллекта, влияющих на
                                эффективность
                                сотрудника на рабочем месте.
                            </p>
                        </li>
                    </ul>
                    <div class="theory-item-bg-green">
                        <div class="theory-item-bg-green-wrapper">
                            <h4>Эталонный профиль позволяет:</h4>
                            <ul class="reference-description">
                                <li>
                                    <img src="@/assets/images/conclusion/conclusion4.svg" alt="">
                                    <p class="item-description">Выявлять управленческий потенциал сотрудников и потенциал продуктивности команд (кто готовый управленческий резерв, в кого целесообразно вкладываться, кому не мешать работать в качестве хорошего специалиста, кто может быть наставником и для кого).</p>
                                    <a class="btn btn-bg-white" style="display: none" href="#table" @click='sort()'>Сравнить</a>
                                </li>
                                <li>
                                    <img src="@/assets/images/conclusion/conclusion5.svg" alt="">
                                    <p class="item-description">Подключить функционал построения индивидуальных и командных траекторий развития по критически важным для повышения продуктивности способностям эмоционального интеллекта на основе выявленных сильных сторон и потенциала для роста.</p>
                                    <a class="btn btn-bg-white" style="display: none" href="#table">Показать всех</a>
                                </li>
                                <li>
                                    <img src="@/assets/images/conclusion/conclusion6.svg" alt="">
                                    <p class="item-description">Выявлять более эффективных кандидатов при подборе на определенную должность, соответствующих уровням эталонного ЭИ-профиля (соответствие эталону, в диапазоне соответствия, несоответствие эталону) нажатием одной кнопки.</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </Collapse>
            </section>
            <section class="sample">
                <collapse-button :title="`Выборка:`" @click="isOpenSample = !isOpenSample"/>
                <Collapse :when="isOpenSample" class="collapse">
                    <sample-table/>
                </Collapse>
            </section>
            <section class="additional-analysis">
                <collapse-button :title="`Дополнительный анализ:`" @click="isOpenAnalysis = !isOpenAnalysis"/>
                <Collapse :when="isOpenAnalysis" class="collapse">
                    <additional-analysis :cards="branchData" :imageSrc="imgSrc"/>
                </Collapse>
            </section>
            <section data-html2canvas-ignore class="helpful">
                <div class="helpful-wrapper">
                    <p class="helpful-title">Может быть полезно</p>
                    <ul class="btn-box">
                        <li><a href="#" class="btn  btn-bg-white">Почитать</a></li>
                        <li><a href="#" class="btn  btn-bg-white">Посмотреть</a></li>
                        <li><a href="#" class="btn  btn-bg-white">Заказать</a></li>
                        <li><button @click="showConsultModal" class="btn  btn-bg-white">Получить консультацию</button></li>
                    </ul>
                </div>
                <pop-up-form title="Получить консультацию по продукту"
                             comment="Хочу получить консультацию по Эталонному профилю"
                             v-if="openConsultModal"
                             @closeModal="openConsultModal = false" />
            </section>
        </div>
    </div>
    <div v-else-if="isPreloader" class="table-preloader">
        <preloader/>
    </div>
</template>

<script>
import {ref} from 'vue'
import {Collapse} from 'vue-collapsed'
import {useStore} from "vuex";
import CustomCheckbox from "@/components/Forms/Fields/CustomCheckbox.vue";
import {useRoute} from "vue-router";
import {computed} from "@vue/runtime-core";
import ParametersCards from "./ParametersCards/ParametersCards.vue"
import DetailedReportCards from "./DetailedReportCards/DetailedReportCards.vue"
import SampleTable from "./Sample/Sample.vue"
import AdditionalAnalysis from "./AdditionalAnalysis/AdditionalAnalysis.vue"
import cards from "./ParametersCards/parametersCardsList"
import Preloader from "../../components/Technical/Preloader.vue";
import CollapseButton from "./components/CollapseButton/CollapseButton.vue"
import UsersTable from "./UsersTable/UsersTable.vue"
import PopUpForm from "../../components/Popups/PopUpForm.vue";
import {getScoreTranslateForm, getWordFormBasedOnTheCount} from "@/views/Report/utils";
import html2pdf from "html2pdf.js";

export default {
  methods: {getScoreTranslateForm, getWordFormBasedOnTheCount},
    components: {
        PopUpForm,
        Collapse,
        UsersTable,
        CustomCheckbox,
        ParametersCards,
        DetailedReportCards,
        Preloader,
        SampleTable,
        AdditionalAnalysis,
        CollapseButton,
    },
    name: "profile-report",

    setup() {
        const store = useStore(),
            route = useRoute();

        const isOpenDetailed = ref(false),
            isOpenTheory = ref(true),
            isOpenSample = ref(true),
            isOpenAnalysis = ref(true),
            structure = ref(true),
            isPreloader = ref(false),
            usersTable = ref(null),
            openConsultModal = ref(false),
            imgSrc = ref(),
            isPrinting = ref(false);

        const detailedBasicItems = [{
            index: 0,
            title: "Общий ЭИ",
            range: "94 — 112",
            structureClass: ["box-1"],
            description: "Конформизм, на первый взгляд, точно представляет собой комплекс. Очевидно, что мышлениерелевантно иллюстрирует депрессивный код.Воспитание гомогенно притягивает гештальт, чтолишний раз подтверждает правоту З.Фрейда.Генезис, как принято считать, изменяем.Психе, по определению, интегрирует объект.Дело в том, что бессознательное прекрасно отталкивает эмпирический аутотренинг, так, например, Ричард Бендлер для построения эффективных состояний использовал изменение субмодальностей."
        },
            {
                index: 1,
                title: "Опытный ЭИ",
                range: "94 — 112",
                structureClass: ["box-2"],
                description: "Лидерство, например, интегрирует бихевиоризм, следовательно тенденция к конформизму связана с менее низким интеллектом. Здесь автор сталкивает два таких достаточно далёких друг от друга явления как ассоцианизм неустойчиво притягивает феноменологический стимул, в полном соответствии с основными законами развития человека. Чувство, на первый взгляд, начинает ролевой гомеостаз. Чувство представляет собой инсайт. Сновидение косвенно."
            },
            {
                index: 2,
                title: "Стратегический ЭИ",
                range: "94 — 112",
                structureClass: ["box-3"],
                description: "Этот концепт элиминирует концепт «нормального», однако лидерство однородно отталкивает материалистический эскапизм. Сознание критично иллюстрирует концептуальный архетип. Индивидуальность, как бы это ни казалось парадоксальным, доступна. Объект противоречиво дает аутотренинг, следовательно тенденция к конформизму связана с менее низким интеллектом. Сознание кумулятивно. Действие отчуждает методологический онтогенез речи, как и предсказывает теория о бесполезном знании."
            }
        ]
        const detailedBranchesItems = [{
            index: 0,
            title: "Распознавание эмоций",
            range: "100 — 108",
            structureClass: ["box-4"],
            description: "Конформизм, на первый взгляд, точно представляет собой комплекс. Очевидно, что мышлениерелевантно иллюстрирует депрессивный код.Воспитание гомогенно притягивает гештальт, чтолишний раз подтверждает правоту З.Фрейда.Генезис, как принято считать, изменяем.Психе, по определению, интегрирует объект.Дело в том, что бессознательное прекрасно отталкивает эмпирический аутотренинг, так, например, Ричард Бендлер для построения эффективных состояний использовал изменение субмодальностей."
        },
            {
                index: 1,
                title: "Управление эмоциями",
                range: "92 — 119",
                structureClass: ["box-5"],
                description: "Лидерство, например, интегрирует бихевиоризм, следовательно тенденция к конформизму связана с менее низким интеллектом. Здесь автор сталкивает два таких достаточно далёких друг от друга явления как ассоцианизм неустойчиво притягивает феноменологический стимул, в полном соответствии с основными законами развития человека. Чувство, на первый взгляд, начинает ролевой гомеостаз. Чувство представляет собой инсайт. Сновидение косвенно."
            }
        ]
        const detailedSubbranchItems = [{
            index: 0,
            title: "Применение эмоций в действиях и мышлении",
            range: "100 — 108",
            structureClass: ["box-6"],
            description: "Конформизм, на первый взгляд, точно представляет собой комплекс. Очевидно, что мышлениерелевантно иллюстрирует депрессивный код.Воспитание гомогенно притягивает гештальт, чтолишний раз подтверждает правоту З.Фрейда.Генезис, как принято считать, изменяем.Психе, по определению, интегрирует объект.Дело в том, что бессознательное прекрасно отталкивает эмпирический аутотренинг, так, например, Ричард Бендлер для построения эффективных состояний использовал изменение субмодальностей."
        },
            {
                index: 1,
                title: "Эмпатия",
                range: "92 — 119",
                structureClass: ["box-7"],
                description: "Лидерство, например, интегрирует бихевиоризм, следовательно тенденция к конформизму связана с менее низким интеллектом. Здесь автор сталкивает два таких достаточно далёких друг от друга явления как ассоцианизм неустойчиво притягивает феноменологический стимул, в полном соответствии с основными законами развития человека. Чувство, на первый взгляд, начинает ролевой гомеостаз. Чувство представляет собой инсайт. Сновидение косвенно."
            },
            {
                index: 2,
                title: "Влияние на эмоции других",
                range: "92 — 119",
                structureClass: ["box-8"],
                description: "Лидерство, например, интегрирует бихевиоризм, следовательно тенденция к конформизму связана с менее низким интеллектом. Здесь автор сталкивает два таких достаточно далёких друг от друга явления как ассоцианизм неустойчиво притягивает феноменологический стимул, в полном соответствии с основными законами развития человека. Чувство, на первый взгляд, начинает ролевой гомеостаз. Чувство представляет собой инсайт. Сновидение косвенно."
            }
        ]

        const companyName = computed(() => store.state.profile.profile.company.name)
        const referenceReport = computed(() => store.state.analytics.referenceReport)

        const treeValuesSorted = ref([]);

        const getSmallestAndHighestValuesOfImportantTrees = () => {
          cards.forEach(function (card) {
            if (card.important) {
              treeValuesSorted.value.push(card.max);
            }
          });
          treeValuesSorted.value.sort((a, b) => b - a);
        }

        let branchData = ref([]);

        if (!referenceReport.value) {
            isPreloader.value = true;
            store.dispatch("analytics/getReferenceReport", route.params.id)
                .then(() => {
                    isPreloader.value = false
                    setCardsInfo();
                    getSmallestAndHighestValuesOfImportantTrees();
                    imgSrc.value = referenceReport.value.image;
                })
        } else {
            setCardsInfo();
            getSmallestAndHighestValuesOfImportantTrees();
            imgSrc.value = referenceReport.value.image;
        }

        function setCardsInfo() {
            Object.keys(referenceReport.value.score).forEach(function (key) {
                const score = referenceReport.value.score[key];
                const low_kpi_value = referenceReport.value.low_kpi_group[key];
                if (score !== null && score.score_from !== null && score.score_to !== null) {
                    const card = cards.get(key)
                    if (!card) {
                        return
                    }
                    card.min = score.score_from
                    card.max = score.score_to
                    card.avg_low_kpi = low_kpi_value;
                    card.important = score.key

                }
            });
            branchData.value = Array.from(cards.values());
        }

        const sort = () => {
            usersTable.value.sortByIdentifyCandidates()
        };

        const showConsultModal = () => {
            openConsultModal.value = !openConsultModal.value
        }

      const print = () => {
        structure.value = true;
        setTimeout(generatePdf, 500);
      }

      const generatePdf = () => {
        // Подгоняет блоки страницы под пдф

        isPrinting.value = true;

        //Открывает все секции для печати
        isOpenTheory.value = true;
        isOpenSample.value = true;
        isOpenAnalysis.value = true;

        // Заголовки
        let detailedHeaderExperience = document.querySelector('.detailing-domain__experienced');
        let detailedHeaderStrategy = document.querySelector('.detailing-domain__strategic');

        detailedHeaderExperience.style.position = 'absolute';
        detailedHeaderExperience.style.visibility = 'hidden';
        detailedHeaderStrategy.style.position = 'absolute';
        detailedHeaderStrategy.style.visibility = 'hidden';

        // Карточки
        let cardSection = document.querySelector('.card_section');
        cardSection.style.paddingTop = '0';

        // Картинка
        let theorySection = document.querySelector('.theory');
        theorySection.style.paddingTop = '0';

        // Динамически вычисляемые отступы списка
        let cardList = Array.from(cards.values());
        let importantCards = cardList.filter((el) => el.important);

        let margin = 570;
        let oneCardHeight = 30;

        if (!importantCards.length) {
          margin = 25;
        } else {
          margin -= importantCards.length * oneCardHeight;
        }

        theorySection.style.marginTop = margin + 'px';

        let analysisList = document.querySelector('.analysis__list');
        analysisList.style.rowGap = '5px';

        let sampleBox = document.querySelector('.samples-box');
        sampleBox.style.paddingBottom = '60px';

        // Графики выравниваются по страницам
        let firstGraphic = document.querySelector('.analysis__content:first-child');
        let secondGraphic = document.querySelector('.analysis__content:nth-child(2)');

        firstGraphic.style.marginBottom = '130px';
        secondGraphic.style.marginBottom = '130px';

        let fifthGraphic = document.querySelector('.analysis__content:nth-child(5)');
        let sixthGraphic = document.querySelector('.analysis__content:nth-child(6)');

        fifthGraphic.style.marginBottom = '100px';
        sixthGraphic.style.marginBottom = '100px';

        let ninthGraphic = document.querySelector('.analysis__content:nth-child(9)');
        let tenthGraphic = document.querySelector('.analysis__content:nth-child(10)');

        ninthGraphic.style.marginBottom = '120px';
        tenthGraphic.style.marginBottom = '120px';

        let thirteenthGraphic = document.querySelector('.analysis__content:nth-child(13)');
        let fourteenthGraphic = document.querySelector('.analysis__content:nth-child(14)');

        thirteenthGraphic.style.marginBottom = '125px';
        fourteenthGraphic.style.marginBottom = '125px';

        let finalGraphic = document.querySelector('.analysis__content:nth-child(17)');
        finalGraphic.style.marginBottom = '30px';

        let eiComparisonGraphic = document.querySelector('.comparison_table');
        eiComparisonGraphic.style.rowGap = '3px';
        eiComparisonGraphic.style.paddingTop = '90px'
        eiComparisonGraphic.style.paddingBottom = '90px';

        let tableLegend = document.querySelector('.table_legend');
        tableLegend.style.fontSize = '10px';

        let sloganBlock = document.querySelector('.slogan_block');
        let sloganMargin = 60;
        let listElementHeight = 35;

        if (!importantCards.length) {
          sloganMargin = 0;
        } else if (importantCards.length < cardList.length) {
          sloganMargin += ((cardList.length - importantCards.length) * listElementHeight) + 10;
        }

        let squareCollection = document.querySelectorAll('.square');
        squareCollection.forEach((square) => {
          square.style.height = '20px';
        });

        sloganBlock.style.marginTop = sloganMargin + 'px';
        sloganBlock.style.marginBottom = '20px';

        html2pdf(document.getElementById("print_element"), {
          autoPaging: 'text',
          margin: [12, 8, 15, 8],
          filename: "reference_profile_" + Date.now() + ".pdf",
          html2canvas: {
            dpi: 192,
            scale: 4,
            windowWidth: 1000,
          },
          pagebreak: {mode: 'css'}
        }).then(function () {
          // Восстанавливает исходный вид страницы после генерации pdf

          detailedHeaderExperience.style.position = 'initial';
          detailedHeaderExperience.style.visibility = 'visible';
          detailedHeaderStrategy.style.position = 'initial';
          detailedHeaderStrategy.style.visibility = 'visible';

          theorySection.style.marginTop = '0';
          sampleBox.style.paddingBottom = '20px';

          eiComparisonGraphic.style.rowGap = '10px';
          tableLegend.style.fontSize = '16px';

          sloganBlock.style.marginTop = '0';
          sloganBlock.style.marginBottom = '0';

          firstGraphic.style.marginBottom = '0';
          secondGraphic.style.marginBottom = '0';
          fifthGraphic.style.marginBottom = '0';
          sixthGraphic.style.marginBottom = '0';
          ninthGraphic.style.marginBottom = '0';
          tenthGraphic.style.marginBottom = '0';
          thirteenthGraphic.style.marginBottom = '0';
          fourteenthGraphic.style.marginBottom = '0';
          finalGraphic.style.marginBottom = '0';

          isPrinting.value = false;
        });
      }

      let listStructure = {
        domain_1: {
          title: "Опытный домен",
          branches: {
            branch_1: {
              title: "Распознавание эмоций",
            },
            branch_2: {
              title: "Использование эмоций для решения задач",
            },
          }
        },
        domain_2: {
          title: "Стратегический домен",
          branches: {
            branch_3: {
              title: "Анализ эмоций",
            },
            branch_4: {
              title: "Управление эмоциями",
            },
          }
        }
      }

        return {
            print,
            isOpenDetailed,
            isOpenTheory,
            isOpenSample,
            isOpenAnalysis,
            structure,
            detailedBasicItems,
            detailedBranchesItems,
            detailedSubbranchItems,
            cards,
            companyName,
            referenceReport,
            isPreloader,
            sort,
            usersTable,
            openConsultModal,
            showConsultModal,
            treeValuesSorted,
            branchData,
            imgSrc,
            listStructure,
            isPrinting
        };
    }
};
</script>

<style lang="scss" scoped>

section {
  padding-top: 30px;
}
section > .collapse-btn {
  padding-top: 10px;
  height: 80px;
}

.checkbox {
  padding-bottom: 19px;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;

  @media(max-width: 768px) {
    font-size: 14px;
    line-height: 16px;
  }
}

.header_section {
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  padding-bottom: 24px;
  border-bottom: solid 2px;
  border-bottom-color: #21B579;

  @media (max-width: 1001px) {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

.references-report-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.bg-color-green {
  position: relative;
  background-color: #2CC185;
  color: #fff;
  text-align: start;
  padding: 61px 40px 49px 40px;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  width: 100%;

  @media (max-width: 1001px) {
    padding: 10px 20px 20px;
  }

  @media (max-width: 900px) {
    padding: 17px 22px 16px;
  }
}

.bg-color-white {
  background-color: #fff;
  text-align: start;
  padding: 38px 40px 49px 40px;

  @media (max-width: 900px) {
    padding: 17px 22px 16px;
  }
}

.btn-bg-white {
  background-color: #fff;
  color: #2CC185;
  margin-right: 8px;

  @media (max-width: 1040px) {
    margin-top: 8px;
    font-size: 12px;
    line-height: 16px;
    white-space: nowrap;
  }
}

.parameters {
  padding-top: 30px;

  @media (max-width: 1001px) {
    padding-top: 10px;
  }
}

.parameters-title {
  font-weight: 400;
  font-size: 36px;
  line-height: 42px;
  text-align: start;
  padding-bottom: 10px;

  @media (max-width: 1001px) {
    font-size: 16px;
    line-height: 22px;
  }

  @media(max-width: 768px) {
    font-size: 20px;
    line-height: 24px;
  }
}
.parameters-cards-no-structure{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-items: center;

    @media (max-width: 1240px) {
        grid-template-columns: repeat(2, 1fr);
    }
}
.parameters-cards {
  display: grid;
  align-items: center;
  justify-content: center;
  justify-items: center;
  grid-template-areas:
    " . general general . "
    " . arrowMobile arrowMobile . "
    " experienced experienced strategic strategic "
    " arrow arrow arrow2 arrow2 "
    " recognition using understanding management"
    " arrow4 arrow5 arrow6 arrow7 "
    " faces facilitation changes managingYour"
    " images empathy mixing influencing"
    " situations  . dynamics  . ";

  @media (max-width: 990px) {
    grid-template-areas: " . general general . "
      " . arrowMobile arrowMobile . "
      " experienced experienced strategic strategic "
      " title-experienced title-experienced . . "
      " recognition recognition using using "
      " arrow4 arrow4 arrow5 arrow5 "
      " faces faces facilitation facilitation "
      " images images empathy empathy "
      " situations situations . . "
      " title-strategic title-strategic . . "
      " understanding understanding management management"
      " arrow6 arrow6 arrow7 arrow7 "
      " changes changes managingYour managingYour "
      " mixing mixing influencing influencing "
      " dynamics dynamics . . ";
  }
}

.detailing {
  color: #fff;
  padding-bottom: 5px;

  @media (max-width: 1350px) {
    display: none;
  }
}

.collapse {
  transition: height var(--vc-auto-duration) cubic-bezier(0.3, 0, 0.6, 1);
  overflow: auto !important;
  -ms-overflow-style: none !important;
  scrollbar-width: none !important;
}
.collapse::-webkit-scrollbar {
    width: 0;
    height: 0;
}

.detailed-report-cards {
  padding-top: 20px;
  padding-bottom: 40px;
  display: grid;
  gap: 26px;
  grid-template-areas:
    "A ."
    "B C";

  @media (max-width: 900px) {
    grid-template-areas:
      "A"
      "B";
  }
}

.detailed-report-branches-cards {
  padding-top: 20px;
  padding-bottom: 40px;
  display: grid;
  gap: 26px;
  grid-template-areas:
    "D E";

  @media (max-width: 900px) {
    grid-template-areas:
      "D"
      "E";
  }
}

.detailed-report-subbranch-cards {
  padding-top: 20px;
  padding-bottom: 40px;
  display: grid;
  gap: 26px;
  grid-template-areas:
    "F G"
    "H .";

  @media (max-width: 900px) {
    grid-template-areas:
      "F"
      "G"
      "H";
  }
}

.table {
  border-bottom: solid 1px #c9c9c9;
}

@media (max-width: 800px) {
  .table {
    min-width: auto;
  }
}

.conclusion {
    padding-top: 34px;
    padding-bottom: 43px;
}

.conclusion-title {
  font-weight: 400;
  font-size: 36px;
  line-height: 42px;
  text-align: start;
}

.conclusion-cards {
  padding-top: 20px;
  display: grid;
  gap: 36px;
  grid-template-columns: repeat(3, 320px);

  @media(max-width: 1400px) {
    grid-template-columns: repeat(3, 250px);
  }

  @media(max-width: 1200px) {
    grid-template-columns: repeat(3, 183px);
  }
}

.conclusion-item-constent {
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;

  @media(max-width: 1200px) {
    font-size: 12px;
    line-height: 17px;
  }
}

.no-scroll {
    overflow: auto ;
    -ms-overflow-style: none;
    scrollbar-width: none;
}
.no-scroll ::-webkit-scrollbar {
    width: 0;
    height: 0;
}

.theory-items {
  padding-top: 20px;
  padding-bottom: 28px;
  display: grid;
  gap: 36px;
  grid-template-columns: repeat(2, 1fr);

  @media(max-width: 900px) {
    grid-template-columns: 227px;
  }
}

.theory-item-contant {
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;

  @media(max-width: 900px) {
    font-size: 12px;
    line-height: 17px;
  }
}

.theory-item-bg-green {
  background-color: #2CC185;
  color: #fff;
  border-radius: 10px;
}

.theory-item-bg-green-wrapper {
  padding: 29px 38px 44px;
  overflow-x: scroll;

  @media(max-width: 900px) {
    padding: 17px;
  }
}

.reference-description {
  padding-top: 20px;
  display: grid;
  gap: 36px;
  grid-template-columns: repeat(3, 1fr);
}

.item-description {
  padding-top: 10px;
  padding-bottom: 10px;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;

  @media(max-width: 1023px) {
    font-size: 12px;
    line-height: 17px;
  }
}

.additional_description {
  padding: 25px 0;
  text-align: justify;

  @media (min-width: 990px) and (max-width: 1001px) {
    font-size: 10px;
    padding-top: 15px;
    height: 90px;
  }
}

.additional_resume {
  padding: 25px 0;
  text-align: justify;
}

.additional-analysis {
  padding-bottom: 20px;
}

.recommended_score {
  font-weight: bold;
}

.domain_details {
  text-transform: lowercase;
}

.helpful {
  background-color: #2CC185;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.helpful-wrapper {
  padding: 32px 42px 56px;

  @media(max-width: 900px) {
    padding: 13px 19px;
  }
}

.helpful-title {
  color: #fff;
  font-weight: 400;
  font-size: 36px;
  line-height: 42px;
  padding-bottom: 20px;

  @media(max-width: 900px) {
    font-size: 20px;
    line-height: 24px;
  }
}

.btn-box {
  display: flex;
  flex-wrap: wrap;
}

.btn-show-staffs {
  display: block;
  max-width: 620px;
  margin: 10px auto 40px;
  background: #ca84ff;
  color: #fff;
  font-size: 18px;
  font-weight: 1000;
  padding: 20px 40px;
}

@media (max-width: 900px) {
  .no-mobile {
    display: none;
  }
}

.details_list {
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  font-size: 15px;
  line-height: 15px;
}

.list_element {
  margin-left: 10px;
  padding-left: 10px;
  border-left: 1px solid #d4d5d5;
}

.details_list .list_branch {
  position: relative;
  padding-top: 5px;
  padding-left: 10px;
}

.details_list .list_branch:before {
  content: "";
  position: absolute;
  width: 15px;
  height: 1px;
  top: 15px;
  left: -10px;
  background-color: #d4d5d5;
}

.details_list .list_element:last-child {
  position: relative;
  border: none;
}

.details_list .list_element:last-child:before {
  content: "";
  position: absolute;
  width: 1px;
  height: 15px;
  background-color: #d4d5d5;
  top: 0;
  left: 0;
}

.recommended_score {
  font-weight: bold;
}

.details_header {
  width: fit-content;
  padding: 0 2px;
  text-transform: uppercase;
}

.important {
  background-color: #aaf427;
  font-weight: bold;
}

.general_ei {
  padding: 10px 0;
}

.list_details {
  position: relative;
  padding-left: 20px;
}

.list_details:before {
  content: "";
  position: absolute;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: #3D3D3D;
  top: 40%;
  left: 10px;
}

.general_ei,
.list_branch,
.details {
  padding-bottom: 5px;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

@media (max-width: 1001px) {
  .details_list {
    padding: 2px 0;
    font-size: 10px;
    line-height: 15px;
  }

  .general_ei,
  .list_branch,
  .details {
    row-gap: 0;
    padding-bottom: 0;
  }
}

@media (max-width: 600px) {
  .general_ei,
  .list_branch,
  .details {
    row-gap: 5px;
    padding-bottom: 5px;
  }
}

.overlay {
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 5;
  background-color: rgba(16, 16, 16, 0.62);
}

.modal_block {
  display: flex;
  width: 80%;
  height: 100vh;
  position: absolute;
  z-index: 6;
}

.modal_text_wrapper {
  margin: auto;
  display: flex;
  width: 420px;
  height: 200px;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  background-color: #ffffff;
}

@media (max-width: 840px) {
  .modal_block {
    width: 100%;
  }

  .modal_text_wrapper {
    width: 100%;
  }
}

@media (min-width: 770px) and (max-width: 900px) {
  .modal_block {
    width: 60%;
  }

  .modal_text_wrapper {
    width: 400px;
  }
}

</style>