const cards = new Map([
    [
        "total",
        {
            id: 1,
            title: "Общий ЭИ",
            max: null,
            min: null,
            descriptionContent: "Эмоциональный интеллект - это четко определяемая и измеряемая когнитивная способность перерабатывать информацию, содержащуюся в эмоциях, определять значение эмоций, их связи друг с другом, использовать эмоциональную информацию в качестве основы для мышления и принятия решений.",
            descriptionFooter: null,
            structureClass: ["general"],
            structure: false,
            domain: "",
            branch: "",
            avg_low_kpi: null,
            graphic_order: 0,
        },
    ],
    [
        "domain_1",
        {
            id: 1,
            title: "Опытный домен",
            max: null,
            min: null,
            descriptionContent: "Опытный эмоциональный интеллект - это способность воспринимать эмоциональную информацию, отвечать на нее и распоряжаться ею, не обязательно при этом понимая ее. Опытный ЭИ показывает выраженность способности воспринимать эмоциональный опыт, распознавать его, сравнивать с другими чувствами и ощущениями и понимать, как этот опыт взаимодействует с процессами мышления.",
            descriptionFooter: null,
            structureClass: ["experienced"],
            domain: "Опытный домен",
            branch: "",
            avg_low_kpi: null,
            graphic_order: 1,
        },
    ],
    [
        "domain_2",
        {
            id: 2,
            title: "Стратегический домен",
            max: null,
            min: null,
            descriptionContent: "Стратегический эмоциональный интеллект - это способность понимать эмоциональную информацию и использовать ее стратегически – для планирования и саморегуляции. Стратегический ЭИ показывает, насколько хорошо человек может понимать эмоции и управлять ими, не обязательно при этом хорошо воспринимая чувства или полностью испытывая их, а также в какой мере человек способен управлять своими и чужими эмоциями.",
            descriptionFooter: null,
            structureClass: ["strategic"],
            domain: "Стратегический домен",
            branch: "",
            avg_low_kpi: null,
            graphic_order: 2,
        },
    ],
    [
        "branch_1",
        {
            id: 3,
            title: "Распознавание эмоций",
            max: null,
            min: null,
            descriptionContent: "Распознавание эмоций – это способность распознавать свои эмоции и эмоции окружающих через речь, мимику, внешний вид, физические проявления эмоций, определять эмоции через произведения искусства, способность соотносить свои телесные ощущения с переживаемыми эмоциями, дифференцировать истинные и ложные выражения эмоций и чувств.",
            descriptionFooter: null,
            structureClass: ["recognition"],
            detailingClass: ["parameter-card-detailing"],
            domain: "Опытный домен",
            branch: "",
            avg_low_kpi: null,
            graphic_order: 3,
        },
    ],
    [
        "branch_2",
        {
            id: 4,
            title: "Использование эмоций для решения задач",
            max: null,
            min: null,
            descriptionContent: "Использование эмоций для решения задач – это способность грамотно использовать определенные эмоции в качестве основы для принятия решений, для повышения эффективности мышления и деятельности, для решения конкретных задач.",
            descriptionFooter: null,
            structureClass: ["using"],
            detailingClass: ["parameter-card-detailing"],
            domain: "Опытный домен",
            branch: "",
            avg_low_kpi: null,
            graphic_order: 4,
        },
    ],
    [
        "branch_3",
        {
            id: 5,
            title: "Анализ эмоций",
            max: null,
            min: null,
            descriptionContent: "Анализ эмоций – это способность понимать и анализировать сложные эмоции и чувства, распознавать причины эмоций, а также прогнозировать последствия эмоций и эмоциональные сценарии, как эмоции переходят от одной к другой.",
            descriptionFooter: null,
            structureClass: ["understanding"],
            detailingClass: ["parameter-card-detailing"],
            domain: "Стратегический домен",
            branch: "",
            avg_low_kpi: null,
            graphic_order: 5,
        },
    ],
    [
        "branch_4",
        {
            id: 6,
            title: "Управление эмоциями",
            max: null,
            min: null,
            descriptionContent: "Управление эмоциями – это способность регулировать свои эмоции и влиять на эмоции других людей, создавать эффективные стратегии, которые помогают вам в достижении целей, способность «заряжать» своими эмоциями окружающих, вовлекая их в свои идеи.",
            descriptionFooter: null,
            structureClass: ["management"],
            detailingClass: ["parameter-card-detailing"],
            domain: "Стратегический домен",
            branch: "",
            avg_low_kpi: null,
            graphic_order: 6,
        },
    ],
    [
        "section_1",
        {
            id: 7,
            title: "Распознавание эмоций в лицах",
            max: null,
            min: null,
            descriptionContent: "Раздел, направленный на эмоциональное оценивание лиц.",
            descriptionFooter: null,
            structureClass: ["faces"],
            detailingClass: ["parameter-card-detailing"],
            domain: "Опытный домен",
            branch: "Распознавание эмоций",
            avg_low_kpi: null,
            graphic_order: 7,
        },
    ],
    [
        "section_2",
        {
            id: 8,
            title: "Применение эмоций в действиях и мышлении",
            max: null,
            min: null,
            descriptionContent: "Раздел, направленный на измерение способности к определению эмоций, эффективных для содействия мышлению в конкретной деятельности.",
            descriptionFooter: null,
            structureClass: ["facilitation"],
            detailingClass: ["parameter-card-detailing"],
            domain: "Опытный домен",
            branch: "Использование эмоций для решения задач",
            avg_low_kpi: null,
            graphic_order: 10,
        },
    ],
    [
        "section_3",
        {
            id: 9,
            title: "Анализ смены эмоций",
            max: null,
            min: null,
            descriptionContent: "Раздел, направленный на измерение способности человека понимать особенности перехода эмоций друг в друга, механизмы, провоцирующие их изменения, а также специфику протекания эмоций с течением времени.",
            descriptionFooter: null,
            structureClass: ["changes"],
            detailingClass: ["parameter-card-detailing"],
            domain: "Стратегический домен",
            branch: "Анализ эмоций",
            avg_low_kpi: null,
            graphic_order: 12,
        },
    ],
    [
        "section_4",
        {
            id: 10,
            title: "Регуляция своих эмоций",
            max: null,
            min: null,
            descriptionContent: "Раздел, направленный на изучение способности человека регулировать собственные эмоциональные состояния.",
            descriptionFooter: null,
            structureClass: ["managingYour"],
            detailingClass: ["parameter-card-detailing"],
            domain: "Стратегический домен",
            branch: "Управление эмоциями",
            avg_low_kpi: null,
            graphic_order: 15,
        },
    ],
    [
        "section_5",
        {
            id: 11,
            title: "Распознавание эмоций в среде",
            max: null,
            min: null,
            descriptionContent: "Раздел, направленный на измерение способности человека определять эмоции в окружающем пространстве, среде, произведениях искусства.",
            descriptionFooter: null,
            structureClass: ["images"],
            detailingClass: ["parameter-card-detailing"],
            domain: "Опытный домен",
            branch: "Распознавание эмоций",
            avg_low_kpi: null,
            graphic_order: 8,
        },
    ],
    [
        "section_6",
        {
            id: 12,
            title: "Эмпатия",
            max: null,
            min: null,
            descriptionContent: "Раздел, направленный на измерение способности человека описывать свои эмоциональные состояния и почувствовать эмоции другого.",
            descriptionFooter: null,
            structureClass: ["empathy"],
            detailingClass: ["parameter-card-detailing"],
            domain: "Опытный домен",
            branch: "Использование эмоций для решения задач",
            avg_low_kpi: null,
            graphic_order: 11,
        },
    ],
    [
        "section_7",
        {
            id: 13,
            title: "Анализ соединения эмоций в чувства",
            max: null,
            min: null,
            descriptionContent: "Раздел, направленный на диагностику способности человека различать эмоции, входящие в комплексные чувства, а также понимать взаимодействие эмоций между собой.",
            descriptionFooter: null,
            structureClass: ["mixing"],
            detailingClass: ["parameter-card-detailing"],
            domain: "Стратегический домен",
            branch: "Анализ эмоций",
            avg_low_kpi: null,
            graphic_order: 13,
        },
    ],
    [
        "section_8",
        {
            id: 14,
            title: "Влияние на эмоции других",
            max: null,
            min: null,
            descriptionContent: "Раздел, направленный на измерение способности человека регулировать эмоциональные состояния других людей.",
            descriptionFooter: null,
            structureClass: ["influencing"],
            detailingClass: ["parameter-card-detailing"],
            domain: "Стратегический домен",
            branch: "Управление эмоциями",
            avg_low_kpi: null,
            graphic_order: 16,
        },
    ],
    [
        "section_9a",
        {
            id: 15,
            title: "Распознавание эмоций в контексте ситуаций",
            max: null,
            min: null,
            descriptionContent: "Раздел, направленный на оценку способности человека распознавать  эмоции в динамике и с учетом дополнительных факторов: особенности позы, положение тела позволяют более точно распознать эмоцию",
            descriptionFooter: null,
            structureClass: ["situations"],
            detailingClass: ["parameter-card-detailing"],
            domain: "Опытный домен",
            branch: "Распознавание эмоций",
            avg_low_kpi: null,
            graphic_order: 9,
        },
    ],
    [
        "section_9b",
        {
            id: 16,
            title: "Анализ изменения эмоций в контексте ситуаций",
            max: null,
            min: null,
            descriptionContent: "Раздел, направленный на измерение способности оценивать динамику эмоциональных состояний с учетом внешних факторов и объективного хода событий.",
            descriptionFooter: null,
            structureClass: ["dynamics"],
            detailingClass: ["parameter-card-detailing"],
            domain: "Стратегический домен",
            branch: "Анализ эмоций",
            avg_low_kpi: null,
            graphic_order: 14,
        },
    ],
]);

export default cards;
