<template>
  <section class="additional_comparison_section">
    <h3 class="additional_comparison_header">Структура эталонного ЭИ-профиля</h3>
    <div class="comparison_table">
      <div v-for="(card, index) in sortedCards" :key="index" class="table_body">
        <div class="table_row" :class="{'row_gap': rowGapIndexes.includes(index)}">
          <div class="row_header">
            <p :class="{'header_important': card.important}">{{ card.title }}</p>
          </div>
          <div class="row_body">
            <div class="min_progress bar"
                 :style="{'width': (calculateWidth(card.avg_low_kpi, 150) + (card.avg_low_kpi > card.max ? 13 : 0) )+ '%', 'z-index': card.avg_low_kpi > card.max ? 1 : 2}">
              <span>{{ card.avg_low_kpi }}</span>
            </div>
            <div class="max_progress bar"
                 :style="{'width': (calculateWidth(card.max, 150) + (card.max < card.avg_low_kpi ? 0 : 13)) + '%', 'z-index': card.max < card.avg_low_kpi ? 2 : 1}">
              <span>{{ card.max }}</span>
            </div>
            <div class="empty_bar" :class="{'progress_important': card.important}"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="table_legend">
      <div>
        <div class="block">
          <div class="square blue"></div>
          <div>
            <p>Группа с низким показателем достижения KPI</p>
          </div>
        </div>
        <div class="block">
          <div class="square dark_blue"></div>
          <div>
            <p>Группа с высоким показателем достижения KPI</p>
          </div>
        </div>
      </div>
      <div>
        <div class="block">
          <div class="square important"></div>
          <div>
            <p>Статистически значимые разделы эталонного ЭИ-профиля</p>
          </div>
        </div>
      </div>
    </div>
    <div class="details">
      <ul class="general_list">
        <li>
          В целом уровень <b>ЭИ выше</b> в группе с <b>высокими KPI.</b>
        </li>
        <li v-if="cards.find((el) => el.important)">
          {{ getWordFormBasedOnTheCount(cards.filter(card => card.important).length, 'Выявлен', 'Выявлены', 'Выявлены') }}
          <span
              class="header_important"> {{ cards.filter(card => card.important).length + " " + getWordFormBasedOnTheCount(cards.filter(card => card.important).length, 'ключевой', 'ключевых', 'ключевых') + " " + getWordFormBasedOnTheCount(cards.filter(card => card.important).length, 'параметр', 'параметра', 'параметров') }}</span>,
          показатели {{ getWordFormBasedOnTheCount(cards.filter(card => card.important).length, 'которого', 'которых', 'которых') }} критически важны
          для эффективности cпециалиста по работе с клиентами, для фокусного развития и выбора специалистов в кадровый
          резерв, которые потенциально будут <b>более эффективны в достижении KPI</b>.
        </li>
        <li v-if="cards.find((el) => el.important)">
          На остальные показатели также можно ориентироваться (выбирать не ниже указанных баллов на графике), но <b>наибольший
          вклад</b> в достижение KPI вносят именно эти параметры.
        </li>
      </ul>
    </div>
    <div>
      <span v-if="cards.find((el) => el.important)">Фокус при принятии кадровых решений:</span>
      <ul class="branch_list">
        <template v-for="card in sortedCards" :key="card">
          <li v-if="card.important" class="header_important">
            {{ card.title }}
          </li>
        </template>
      </ul>
    </div>
    <div class="slogan_block">
      <p class="slogan">Выбираем лучших, экономим время и ресурсы</p>
    </div>
  </section>
</template>

<script>
import {ref, watch} from "@vue/runtime-core";
import {getWordFormBasedOnTheCount} from "@/views/Report/utils";
import {computed} from "@vue/reactivity";

export default {
  name: "AdditionalComparison",
  methods: {getWordFormBasedOnTheCount},
  props: {
    cards: {
      type: Array,
      required: true
    }
  },
  setup(props) {
    let rowGapIndexes = [0, 2, 6];
    let sortedCards = computed(() => {
      let tempCards = props.cards;
      return tempCards.sort((card_a, card_b) => card_a.graphic_order - card_b.graphic_order);
    })

    const calculateWidth = (score, maxScore) => {
      return (score / maxScore) * 100;
    }

    const calculateDifference = (score, maxScore) => {
      return maxScore - score;
    }

    return {
      rowGapIndexes,
      calculateWidth,
      calculateDifference,
      sortedCards,
    }
  },
}
</script>


<style scoped lang="scss">
.comparison_table {
  padding: 25px 0;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  border-bottom: 2px #8ea9db solid;
}

.table_body {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}

.table_row {
  display: flex;
  justify-content: space-between;
  column-gap: 10px;
}

.row_header {
  display: flex;
  justify-content: flex-end;
  width: 60%;
  text-transform: uppercase;
}

.header_important {
  padding: 0 5px;
  width: fit-content;
  background-color: #aaf427;
}

.row_body {
  position: relative;
  width: 40%;
  height: 24.59px;
  display: flex;
  font-weight: bold;
}

.row_gap {
  margin-bottom: 25px;
}

.additional_comparison_header {
  font-size: 32px;
  text-transform: uppercase;
}

.bar {
  position: absolute;
  display: flex;
  justify-content: right;
}

.min_progress {
  padding-right: 5px;
  color: #ffffff;
  background-color: #8ea9db;
}

.max_progress {
  padding-left: 5px;
  background-color: #2e75b5;
}

.empty_bar {
  width: 100%;
  background-color: #ecf1f7;
}

.progress_important {
  background-color: #aaf427;
}

.table_legend {
  display: flex;
  justify-content: space-between;
  border-bottom: 2px #8ea9db solid;
}

.bar span {
  padding: 0 10px;
}

.square {
  width: 25px;
  height: 25px;
}

.block {
  padding: 10px;
  display: flex;
  column-gap: 5px;
}

.square.blue {
  background-color: #8ea9db;
}

.square.dark_blue {
  background-color: #2e75b5;
}

.square.important {
  background-color: #aaf427;
}

.details {
  padding: 25px 0;
}

.slogan_block {
  padding: 25px 0;
}

.slogan {
  text-transform: uppercase;
  font-weight: bold;
}

.general_list,
.branch_list {
  padding: 10px 0 10px 25px;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

.general_list,
.general_list li {
  list-style: initial;
}

.branch_list,
.branch_list li {
  list-style: decimal;
}

@media (max-width: 932px) {
  .comparison_table {
    row-gap: 15px;
  }

  .table_row {
    flex-direction: column;
    row-gap: 10px;
    width: 100%;
  }

  .row_header {
    width: 100%;
    justify-content: flex-start;
    font-size: 14px;
  }

  .row_body {
    width: 100%;
  }
}

@media (max-width: 1400px) {
  .row_header {
    font-size: 12px;
  }
}

@media (min-width: 933px) and (max-width: 1040px) {
  .row_header {
    font-size: 10px;
    width: 50%;
  }

  .row_body {
    width: 50%;
  }
}
</style>