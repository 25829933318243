<template>
  <h3 class="table-title">Лучшие и худшие сотрудники:</h3>

  <base-table
      v-if="shownData.length"
      :columns="usersFields"
      :rows="shownData"
      @sort="(data) => {
        sortValue = data.sortValue;
        sortDirection = data.direction;
      }"
  />

  <table-pagination
      v-if="shownData.length"
      :key="updatePagination"
      :totalPages="1"
      :countOnPage="countOnPage"
      :total="totalResults ?? rowData.length"
      :count="countOnPage > shownData.length ? shownData.length : countOnPage"
      @changePage="changePage"
  />
  <div v-else>
    <p>Нет результатов</p>
  </div>

  <div class="table-footer">
    <div v-if="shownData.length || isActive">
      <button class="btn btn-accent table-footer-btn" @click="() => isActive = !isActive">
                <span v-if="!isActive">Выявить кандидатов в
                соответствии с
                эталоном</span>
        <span v-if="isActive">Показать всех</span>
      </button>
    </div>
    <!--        <div>-->
    <!--            <i class="fa fa-user-circle" aria-hidden="true"></i>-->
    <!--            Кстати, есть подходящие кандидаты в других-->
    <!--            отделах-->
    <!--            <i class="fa fa-angle-down" aria-hidden="true"></i>-->
    <!--        </div>-->
  </div>
</template>

<script>
import {ref} from 'vue'
import {useStore} from "vuex";
import {computed, watch} from "@vue/runtime-core";
import TablePagination from "@/components/OtherElements/TablePagination.vue";
import BaseTable from "@/components/Tables/BaseTable.vue";
import sortData from "@/mixins/sortData";

export default {
  components: {
    BaseTable,
    TablePagination,
  },
  name: "users-table",
  setup() {
    const store = useStore();
    const countOnPage = ref(10);
    const isActive = ref(false);
    const referenceReport = computed(() => store.state.analytics.referenceReport);
    const shownData = computed(() => {

      let data = rowData.value ?? [];

      if (data.length) {

        if (isActive.value) {
          data = data.filter((el => el.group !== 3));
        }

        if (sortValue.value) {
          data = sortData(data, sortValue.value, sortDirection.value);
        } else {
          data = sortData(data, 'name', 'up');
          data = sortData(data, 'group', 'up');
        }

        totalResults.value = data.length;
        data = data.slice(pageDataStart.value, pageDataStart.value + countOnPage.value);
      }

      return data;
    });

    const pageDataStart = ref(0);
    const sortValue = ref('');
    const sortDirection = ref('');
    const updatePagination = ref(false);
    const totalResults = ref();

    const usersFields = [
      {
        headerName: "ФИО",
        field: "name",
        sortable: true,
      },
      {
        headerName: "Общий ЭИ",
        field: "total",
        sortable: true,
      },
      {
        headerName: "Опытный ЭИ",
        field: "domain_1",
        sortable: true,
      },
      {
        headerName: "Стратегический ЭИ",
        field: "domain_2",
        sortable: true,
      },
      {
        headerName: "Использование эмоций для решения задач",
        field: "branch_2",
        sortable: true,
      },
      {
        headerName: "Анализ эмоций",
        field: "branch_3",
        sortable: true,
      },
      {
        headerName: "Применение эмоций в действиях и мышлении",
        field: "section_2",
        sortable: true,
      },
      {
        headerName: "Эмпатия",
        field: "section_6",
        sortable: true,
      },
      {
        headerName: "Понимание соединения эмоций в чувства",
        field: "section_7",
        sortable: true,
      },
      {
        headerName: "KPI",
        field: "kpi",
        sortable: true,
      },
    ];

    const changePage = (page) => {
      pageDataStart.value = (page - 1) * countOnPage.value;
    };

    const buildMapFromObject = (obj, props) => {
      let resultObj = new Map();
      for (let i = 0; i < props.length; i++) {
        const cur = props[i];
        if (cur in obj) {
          resultObj.set(cur, obj[cur])
        }
      }
      return resultObj;
    };

    let measuringParameters = buildMapFromObject(referenceReport.value.score, Object.keys(referenceReport.value.score).filter(key => referenceReport.value.score[key].key));

    function setGroup(data) {
      const total = 2 * measuringParameters.size
      let result = 0
      for (const [key, value] of Object.entries(data)) {
        if (!measuringParameters.has(key)) {
          continue
        }
        const section = measuringParameters.get(key)
        const low = section.score_from
        const upper = section.score_to

        switch (true) {
          case value >= upper:
            result += 2
            break
          case value > low:
            result += 1
            break
        }
      }

      if (result === 0) {
        return 3
      } else if (result === total) {
        return 1
      } else {
        return 2
      }
    }

    const users = computed(() => store.state.analytics.referenceReport.users),
        rowData = computed(() => {
          return users.value.map((user, index) => {
            return {
              id: index,
              name: user.name,
              domain_1: user.domain_1,
              total: user.total,
              domain_2: user.domain_2,
              branch_2: user.branch_2,
              branch_3: user.branch_3,
              section_2: user.section_2,
              section_6: user.section_6,
              kpi: user.kpi,
              section_7: user.section_7,
              group: setGroup(user) || 3
            }
          })
        });

    watch(() => [sortValue.value, sortDirection.value, isActive.value],
        () => {
          totalResults.value = 0;
          pageDataStart.value = 0;
          updatePagination.value = !updatePagination.value;
      }
    );

    return {
      countOnPage,
      rowData,
      isActive,
      usersFields,
      changePage,
      pageDataStart,
      shownData,
      sortValue,
      sortDirection,
      updatePagination,
      totalResults
    }
  },
}
</script>

<style lang="scss" scoped>

.table-title {
  font-weight: 400;
  font-size: 36px;
  line-height: 42px;
  text-align: start;
  word-wrap: break-word;
  margin-bottom: 30px;

  @media (max-width: 900px) {
    font-size: 20px;
    line-height: 24px;
    width: 225px;
  }
}

.table-footer {
  padding-top: 30px;
  padding-bottom: 43px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.table-footer-btn {
  margin-left: 0;
  margin-bottom: 10px
}
</style>